import React from "react";
import { Canvas } from "@react-three/fiber";
import GlassCard from "../components/GlassCard";
import AppBar from "../components/AppBar";
import NavMenu from "../components/NavMenu";
import Footer from "../components/Footer";

const AboutContainer = () => {
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">ABOUT</h1>
      </div>
      <p>
        I am a sofware developer with a passion for learning and building
        solutions. I also have a little background in accounting and business. My main
        goal is to always be expanding my knowledge and skills to become a better developer and problem solver.
      </p>
      <p>My interests include:</p>
      <ul>
        <li> Web and Mobile Development </li>
        <li> ML/AI</li>
        <li> Systems Design </li>
        <li> Quantum Computing </li>
      </ul>
      <p>
        I am currently working at Quant Engineering as a Software Developer.
      </p>
    </div>
  );
};

const ExperienceContainer = () => {
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">EXPERIENCE</h1>
      </div>
      <h2>GoodX Software/Quant Engineering</h2>
      <p>Software Developer (Oct 2022 - Present)</p>
      <h2>Drivatic</h2>
      <p>Software Development Manager (Feb 2022 - Sep 2022)</p>
      <p>Full Stack Developer (Oct 2021 - Jan 2022)</p>
      <h2>Symbit </h2>
      <p>Freelance Full Stack Developer (May 2021 - Oct 2021)</p>
      <h2>Osidon </h2>
      <p>Junior Accountant (Mar 2021 - May 2021)</p>
      <h2>Accsecure </h2>
      <p>Accounting Clerk (Feb 2020 - Mar 2021)</p>
      <h2>Brinant Group </h2>
      <p>Debtors Clerk (Mar 2019 - Feb 2020)</p>
    </div>
  );
};

const SkillsContainer = () => {
  return (
    <div className="box-text">
      <div className="box-title">
        <h1 className="type-effect">SKILLS</h1>
      </div>
      <h2>Languages</h2>
      <ul>
        <li>JavaScript/TypeScript</li>
        <li>Python</li>
        <li>C#</li>
        <li>Java</li>
        <li>Swift</li>
        <li>Go</li>
      </ul>
      <h2>Frameworks</h2>
      <ul>
        <li>React</li>
        <li>React Native</li>
        <li>Angular</li>
        <li>Django</li>
        <li>Flask</li>
        <li>Node</li>
        <li>Express</li>
        <li>ASP.NET Core</li>
      </ul>
      <h2>Tools</h2>
      <ul>
        <li>Git</li>
        <li>GitHub</li>
        <li>Docker</li>
        <li>Azure</li>
        <li>AWS</li>
      </ul>
    </div>
  );
};

const cardHtml = {
  about: <AboutContainer />,
  experience: <ExperienceContainer />,
  skills: <SkillsContainer />,
};

export default function MainScreen() {
  const [activeCard, setActiveCard] = React.useState("about");
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 430;

  return (
    <div id="main-container">
      <AppBar />
      <NavMenu setActiveCard={setActiveCard} activeCard={activeCard} />
      <Canvas camera={{ position: [0, 0, 1] }} style={{ zIndex: -1 }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {!isMobile && <GlassCard html={cardHtml[activeCard]} />}
      </Canvas>
      {isMobile && <div className="mobile-card">{cardHtml[activeCard]}</div>}
      <Footer />
    </div>
  );
}
