import React from 'react';
import { Canvas } from '@react-three/fiber';
import Stars from './Stars';

export default function Background() {
  return (
    <Canvas camera={{ position: [0, 0, 1]}}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Stars />
    </Canvas>
  );
}